<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-alert class="col-12" v-if="message" type="error">
            {{ message }}
          </v-alert>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="editedItem.login"
              label="Login"
              :rules="loginRule"
              maxlength="30"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="editedItem.email"
              label="Email"
              :rules="emailRule"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="editedItem.name"
              label="Imię"
              :rules="nameRule"
              maxlength="50"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="editedItem.surname"
              label="Nazwisko"
              :rules="surnameRule"
              maxlength="50"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="editedItem.pass"
              label="Nowe hasło (zostaw puste aby nie zmieniac)"
              type="password"
              :rules="passwordRule"
              maxlength="32"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="editedItem.repass"
              label="Powtórz nowe hasło (zostaw puste aby nie zmieniac)"
              type="password"
              :rules="[v => new RegExp('^' + editedItem.pass + '$').test(v) || 'Hasła różnią się!']"
              maxlength="32"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="amber accent-3" class="grey--text text--darken-4" x-large v-tilt @click="save">
        Zatwierdz
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'EditAdmin',
    props: {
      user: Object,
    },
    data: () => ({
      dialog: true,
      editedItem: {
        id: null,
        name: null,
        surname: null,
        login: '',
        email: null,
        pass: '',
        repass: '',
      },
      message: null,

      nameRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,50}$/.test(v) ||
          'Imię ma niepoprawny format (maksymalnie 50 znaków, bez cyfr)',
      ],
      surnameRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,50}$/.test(v) ||
          'Nazwisko ma niepoprawny format (maksymalnie 50 znaków, bez cyfr)',
      ],
      emailRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || 'Wprowadzono niepoprawny adres email',
      ],
      loginRule: [
        v => !!v || 'Pole jest wymagane',
        v => (v.length > 7 && v.length < 31) || 'Login musi mieć minmum 8 znaków',
      ],
      passwordRule: [
        v =>
          /^$|^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,32}$/.test(
            v
          ) ||
          'Hasło musi zawierać od 8 do 32 znaków oraz zawierać kombinację trzech typów znaków (małe litery, duże litery, cyfry, znaki specjalne). Nie może zawierać więcej niż dwa te same znaki z rzędu',
      ],
    }),
    watch: {
      user: function () {
        this.getData();
      },
    },
    methods: {
      save: function () {
        this.message = null;
        let data = {};

        Object.assign(data, this.editedItem);
        data.token = this.$store.state.token;
        axios({
          url: this.$store.state.url + '/api/users/edit/admin',
          data: data,
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status !== 200) {
              this.message = resp.data.message;
              document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
            } else {
              window.location.reload();
            }
          })
          .catch();
      },
      getData: function () {
        axios({
          url: this.$store.state.url + '/api/users/get_single/admin',
          data: {
            token: this.$store.state.token,
            id: this.user.id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              let user = resp.data.user;
              this.editedItem.id = user.id;
              this.editedItem.name = user.name;
              this.editedItem.surname = user.surname;
              this.editedItem.login = user.login;
              this.editedItem.email = user.email;
            }
          })
          .catch();
      },
    },
    created() {
      this.getData();
    },
  };
</script>
