<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Administratorzy
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Szukaj" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="tableHeaders"
          :items="users"
          :sort-by="['name', 'created']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              @click="
                editedAdmin = item;
                editData = !editData;
              "
            >
              <p class="ma-0">Edytuj</p>
            </v-btn>
          </template>

          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Administratorzy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">Nowy administrator</v-btn>
                </template>
                <v-card>
                  <v-toolbar flat color="black" :dark="true">
                    <v-toolbar-title>Dodaj admistratora</v-toolbar-title>
                    <v-spacer />
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-alert class="col-12" v-if="message" type="error">
                          {{ message }}
                        </v-alert>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.login"
                            label="Login"
                            :rules="rulesLogin"
                            maxlength="30"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            type="email"
                            :rules="rulesEmail"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Imię"
                            :rules="rulesName"
                            maxlength="50"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.surname"
                            label="Nazwisko"
                            :rules="rulesSurname"
                            maxlength="50"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.pass"
                            label="Hasło"
                            type="password"
                            :rules="rulesPassword"
                            maxlength="32"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.repass"
                            label="Powtórz hasło"
                            type="password"
                            :rules="[
                              v => !!v || 'Pole jest wymagane',
                              v =>
                                new RegExp('^' + editedItem.pass + '$').test(v) ||
                                'Hasła różnią się!',
                            ]"
                            maxlength="32"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      outlined
                      :x-large="true"
                      v-tilt
                      @click="close"
                    >
                      Anuluj
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      :x-large="true"
                      v-tilt
                      @click="save"
                    >
                      Dodaj
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="editData" :dark="false" :light="true" max-width="600px">
                <v-toolbar flat color="black" :dark="true">
                  <v-toolbar-title>Edytuj</v-toolbar-title>
                  <v-spacer />
                  <v-btn @click="editData = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <EditAdmin :user="editedAdmin"></EditAdmin>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';

  import EditAdmin from '../../components/EditAdmin.vue';

  export default {
    components: {
      EditAdmin,
    },
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      rulesLogin: [
        v => !!v || 'Pole jest wymagane',
        v => (v.length > 7 && v.length < 31) || 'Login musi mieć minmum 8 znaków',
      ],
      rulesName: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,50}$/.test(v) ||
          'Imię ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków, bez cyfr)',
      ],
      rulesSurname: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,50}$/.test(v) ||
          'Nazwisko ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków, bez cyfr)',
      ],
      rulesEmail: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || 'Wprowadzono niepoprawny adres email',
      ],
      rulesPassword: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,32}$/.test(
            v
          ) ||
          'Hasło musi zawierać od 8 do 32 znaków oraz zawierać kombinację trzech typów znaków (małe litery, duże litery, cyfry, znaki specjalne). Nie może zawierać więcej niż dwa te same znaki z rzędu',
      ],
      tableHeaders: [
        {
          text: 'LP',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Imię i nazwisko',
          value: 'name',
        },
        {
          text: 'Adres email',
          value: 'email',
        },
        {
          text: 'Edytuj',
          value: 'edit',
        },
      ],
      users: [],
      search: null,
      dialog: false,
      editedItem: {
        name: null,
        surname: null,
        login: '',
        email: null,
        pass: null,
        repass: null,
      },
      defaultItem: {
        name: null,
        surname: null,
        login: '',
        email: null,
        pass: null,
        repass: null,
      },
      message: null,
      editData: false,
      editedAdmin: null,
    }),
    methods: {
      close: function () {
        this.dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        }, 300);
      },
      save: function () {
        axios({
          url: this.$store.state.url + '/api/users/admin/register',
          data: {
            token: this.$store.state.token,
            user: this.editedItem,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status !== 200) {
              this.message = resp.data.message;
            } else {
              window.location.reload();
            }
          })
          .catch();
      },
    },
    created() {
      axios({
        url: this.$store.state.url + '/api/users/get/admin',
        data: {
          token: this.$store.state.token,
        },
        method: 'POST',
      }).then(resp => {
        for (let [i, t] of resp.data.users.entries()) {
          this.users.push({
            id: i + 1,
            name: `${t.name} ${t.surname}`,
            email: t.email,
          });
        }
      });
    },
  };
</script>
<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
